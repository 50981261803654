import { ButtonLinkIcon } from "components";
import moment from "moment";
import React, { useState } from "react";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useGetDeviceAvailabilityDetailPerTickByUsernameQuery } from "../service/reportApi";
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const serialNumberTemplate = (rowData) => (
  
  <Link
    to={`/admin/devices?smartSearch=${rowData.serialNumber}`}
    className="text-primary ml-1"
    target='blank'
  >
    <strong style={{color: '#2065AC', cursor: 'pointer'}}>{rowData.serialNumber}</strong>
  </Link>
)

const columns = [
  {
    header: "TIME STAMP",
    field: "timestamp",
    sortable: true,
  },
  {
    header: "SERIAL NUMBER",
    field: "serialNumber",
    sortable: true,
  },
  {
    header: "AREA",
    field: "area",
    sortable: true,
    wrap: true, // This enables text wrapping for long content
  },
  {
    header: "CUSTOMER",
    field: "customerName",
    sortable: true,
    wrap: true,
  },
  {
    header: "SIM 1 OPERATOR",
    field: "sim1Provider",
    sortable: true,
    wrap: true,
  },
  {
    header: "SIM 1 SIA",
    field: "sia",
    sortable: true,
  },
  {
    header: "SIM 2 OPERATOR",
    field: "sim2Provider",
    sortable: true,
  },
  {
    header: "SIM 2 SIA",
    field: "sim2Sia",
    sortable: true,
  },
];

const DownDevicesDetailModal = ({
  isModalOpen,
  toggleModal,
  timestamp = "",
  timestamps = []
}) => {

  const timeOption = timestamps
  const timestampDiff = timestamps.length > 0 ? timestamps[1] - timestamps[0] : 900000
  const [selectedTime, setSelectedTime] = useState(timestamp);

  const { data: downDeviceData, isFetching: downDeviceDataFetching } =
    useGetDeviceAvailabilityDetailPerTickByUsernameQuery(
      { timestampTs: selectedTime },
      { skip: !selectedTime }
    );

  const formatTimestamp = () => {
    return selectedTime
      ? moment(selectedTime).format("DD MMM YYYY")
      : selectedTime;
    };  

    const getFieldToHeaderMapping = (columns) =>
      columns.reduce((acc, { field, header }) => ({ ...acc, [field]: header }), {});
    
    const mapDataTableForExport = (data) => {
      const fields = columns.map(obj => obj.field);
      
    const fieldReplacements = getFieldToHeaderMapping(columns);

    return {
      head: [fields.map((field) => fieldReplacements[field] || field)],
      body: data.map((item) => fields.map((field) => item[field])),
    };
  };

  const handleExport = () => {
    const reportTitle = `Report Availability_Down Device Detail`;

    let textDate = formatTimestamp().replace(":", ".").replace("-", "");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const { head, body } = mapDataTableForExport(downDeviceData);

    // Create a worksheet and add the headers first
    const ws = XLSX.utils.aoa_to_sheet([head[0], ...body]);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, reportTitle + `_${textDate}` + fileExtension);
  };

function getTopFiveByField(data, field, sliceCount = 0) {
  return data?.reduce((acc, item) => {
      const key = item[field];
      if (key) {
          const existingItem = acc.find(a => a[field] === key);
          existingItem ? existingItem.value++ : acc.push({ name: key, value: 1 });
      }
      return acc;
  }, [])
  .sort((a, b) => b.value - a.value)  // Sort by value in descending order
  .slice(0, sliceCount || undefined);  // Get only the top five
}

const areaTopFive = downDeviceData ? getTopFiveByField(downDeviceData, 'area', 5) : [];
const operatorTopFive = downDeviceData ? getTopFiveByField(downDeviceData, 'operator', 5) : [];
const timeStampOv = downDeviceData ? getTopFiveByField(downDeviceData, 'tunnel') : [];
const downCountOv = downDeviceData ? getTopFiveByField(downDeviceData, 'serialNumber') : [];

const title = () => <span style={{fontWeight: 700, color: 'black'}}>Details of Down Devices at {formatTimestamp()}</span>

const DynamicList = ({ title, items }) => {
  const totalValue = downDeviceData?.length || 1; // Prevent division by zero
  
  return (
      <div style={{ marginBottom: '1.5rem' }}>
          <h4 className="text-muted">
            <span>{title}</span>
          </h4>
          {downDeviceDataFetching ? (
              <div className="text-muted" style={{ fontSize: '14px' }}>
                  <Skeleton width="50%" height="14px" />
                  <Skeleton width="100%" height="6px" className="mt-1" />
              </div>
          ) : items.length > 0 ? (
              items.map((item, index) => (
                  <div key={index} style={{ marginBottom: '0.5rem' }}>
                      <div className="d-flex justify-content-between align-items-center" style={{fontSize: '14px'}}>
                        <span>{item.name}</span>
                        <span>{item.value}</span>
                      </div>
                      <ProgressBar value={item.value / totalValue * 100} style={{ height: '6px' }} />
                  </div>
              ))
          ) : (
              <div className="text-muted" style={{ fontSize: '14px'}}>
                  <div>No data available</div>
                  <ProgressBar value={0} style={{ height: '6px' }}/>
              </div>
          )}
      </div>
  );
};

  return (
  <Dialog header={title} visible={isModalOpen} closable closeOnEscape dismissableMask blockScroll onHide={toggleModal} style={{width: '95vw'}}>
        <Row className="mb-3 d-flex justify-content-between align-items-top">
            <Col style={{paddingTop: '8px', maxHeight: '75vh', overflowY: 'auto'}}>
              <DynamicList title="Top Operators" items={operatorTopFive} />
              <DynamicList title="Top Area" items={areaTopFive} />
              <DynamicList title="Time Stamp Overview" items={timeStampOv} />
            </Col>
            <Col xs={9}>
              <Row className="mb-0 d-flex justify-content-between align-items-top">
                <Col className="d-flex justify-content-start align-items-top">
                  <h4 className="text-muted mb-0"style={{paddingTop: '8px'}}>
                    {downDeviceData?.length} Devices at
                  </h4>
                  <UncontrolledDropdown>
                    <DropdownToggle nav style={{paddingLeft: "10px", paddingTop: "8px"}}>
                      <h4 className="text-default mb-0">
                        {moment(selectedTime - timestampDiff).format('HH:mm')} - {moment(selectedTime).format('HH:mm')}
                        <i className="fa fa-chevron-down fa-1x ml-2" style={{ fontSize: '12px' }}></i>
                      </h4>
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: '120px', overflowY: 'auto' }}>
                        {timeOption.map ((item, id) => (
                          <DropdownItem key={id} onClick={()=> setSelectedTime(item)}>
                            <span className="text-dark">{moment(item - timestampDiff).format('HH:mm')} - {moment(item).format('HH:mm')}</span>
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
                <Col style={{textAlign: 'end', alignContent: 'baseline'}}>
                  <ButtonLinkIcon
                    label="Export"
                    icon="fa-download"
                    onClick={handleExport}
                  />
                </Col>
              </Row>
                <DataTable
                  value={downDeviceData} 
                  scrollable
                  scrollHeight="64.5vh"
                  responsiveLayout="scroll"
                  loading={downDeviceDataFetching}
                  className="p-datatable-striped"
                  rowHover
                  emptyMessage={<div className="text-muted" style={{textAlign: 'center', padding: '20px', fontSize: '14px'}}>No data available</div>}
                >
                  {columns.map((col) => (
                    <Column 
                      key={col.field} 
                      field={col.field} 
                      header={col.header} 
                      sortable={col.sortable} 
                      body={col.field === 'serialNumber' ? serialNumberTemplate : null}
                      style={{ whiteSpace: col.wrap ? 'normal' : 'nowrap' }} // Adjusts wrapping based on the column property
                    />
                  ))}
                </DataTable>
            </Col>
            <Col style={{paddingTop: '8px', maxHeight: '75vh', overflowY: 'auto'}}>
              <DynamicList title="Down Count" items={downCountOv} />
            </Col>
        </Row>
  </Dialog>
  );
};

export default DownDevicesDetailModal;
